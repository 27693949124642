.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 240px);
}

.weekly-events {
  box-shadow: 0px 2px 3px #00000059;
  border-radius: 5px 5px 1px 1px;
  margin: 10px;
  overflow: auto;
  height: calc(100vh - 240px);

  h4 {
    font-weight: bold;
    padding: 10px;
    margin: 0;
    border-bottom: 1px solid #0096fa;
  }

  @media (max-width: 1470px) {
    height: calc(100vh - 300px);
  }
}

.green {
  color: #55b17f;
}

.spin {
  display: inline-block;
  animation: spin 1s linear infinite;
}

/* Keyframes for spinning animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
