#search-results-table,
#search-results-table th,
#search-results-table td {
  border: 1px solid #dee2e6;

  &.table > :not(:last-child) > :last-child > * {
    border-bottom-color: #0096fa !important;
  }
}

.filter-values {
  font-size: 18px;
  text-align: left;
  margin-left: 20px;
}

.loc-value {
  margin-left: 30px;
}

.SearchResults-thead {
  border-bottom-color: #0096fa !important;
  border-bottom-width: 2px;
  width: 100%;

  tr {
    display: grid;
  }
}

#search-scrollable-body {
  height: calc(100vh - 250px);
  overflow-y: auto;
  display: table-caption;
  border-bottom: 1px solid #dee2e6;

  tr {
    display: grid;
    grid-template-columns: 0.75fr 0.75fr 0.75fr 1.5fr 1.5fr 3fr 0.5fr auto;

    td {
      padding: 10px;
      border-bottom: 1px solid #6fb2f3;

      .row {
        padding: 0 10px;
      }
    }

    &:hover {
      background-color: #0096fa28;
      cursor: pointer;
    }
  }
}
