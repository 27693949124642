.alignment {
  display: flex;
  justify-content: space-between;
  margin: 15px;
  flex-wrap: wrap;
}

.item-align {
  display: inline-flex;
  align-items: flex-end;
}

.form-control {
  line-height: 1;
  border-color: #707070;
  margin-top: 2px;
}

h2 {
  margin-top: 1.1rem;
}

.Heading-text {
  /* color: #3CA7F9; */
}

.Heading-guide {
  margin: 0px 0px 5px 0px;
  text-align: left;
  font-size: 12px;
}
.Heading-input {
  margin: 1.1rem 0;
}

.Heading-clear {
  /* margin: 1rem 0; */
}

.Heading-clear button {
  font-size: 16px;
  color: white;
  background-color: #0096fa;
  border-color: #0096fa;
  padding: 5px 10px;
  border-radius: 3px;
  line-height: 21px;

  &.hover {
  }
}

.Heading-search {
  padding: 0.45rem 0.45rem;
  margin-top: 2px;
  color: white;
  background-color: #0096fa;
  border-color: #0096fa;
}

.Heading-search:hover,
.Heading-toggle:hover,
.Weekly-btn:hover {
  color: #0096fa;
  background-color: #daf0fb;
  border-color: #0096fa;
}

.Heading-toggle {
  padding: 0.45rem 0.53rem;
  margin-top: 2px;
  color: white;
  background-color: #0096fa;
  border-color: #0096fa;
  border-color: #0096fa;
}

div.dropdown-menu {
  padding: 0;
}

.Weekly-btn {
  color: white;
  background-color: #0096fa;
  border-color: #0096fa;
  padding: 0.2rem 0.59rem;
  margin-top: 2px;
}

.react-datepicker__header,
.react-datepicker__triangle {
  background-color: #b6e3f8be !important;
  color: #3ca7f9 !important;
}

.react-datepicker__navigation-icon {
  font-size: inherit !important;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: #c6ebfc !important;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #aeaeae !important;
}
