.case-modal {
  overflow: auto;
  height: 50vh;
  max-width: 70vw;
  min-width: 70vw;

  .center-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .modal-body {
    padding: 0;

    .top-section {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 5px;
    }

    .box {
      background-color: white;
      box-shadow: 0px 0px 3px #00000040;
      border-radius: 5px;
      padding: 10px;
      margin: 5px;
      font-size: 14px;

      h5 {
        font-size: 22px;
        font-weight: 600;
      }

      .row {
        margin-left: 1%;
        margin-bottom: 5px;

        .col-3 {
          width: 30%;
          margin-right: 10px;
        }

        .col-8 {
          .dicom-link {
            color: #247cff;
            text-decoration: underline;

            &:hover {
              opacity: 50%;
            }
          }
        }
      }

      .table {
        thead {
          color: #0096fa;

          th {
            border-bottom-color: black !important;
          }
        }

        tbody tr:nth-child(even) {
          background-color: #f6f6f6;
        }
      }
    }
  }
}

.modal-footer {
  border: unset;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
