.upload-modal {
  min-width: 45vw;
  max-width: 45vw;

  .upload-dicom-container {
    margin-bottom: 10px;

    .dropzone {
      border: 1px dashed #0096fa;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 30px 10px;

      p {
        margin: 0;
        color: #0096fa;
        font-size: 1.25rem;
        font-weight: 500;
      }
    }
  }

  .dicom-container {
    max-height: 20vh;
    overflow: auto;

    padding: 10px;
    .dicom-file {
      padding: 10px;
      color: #0096fa;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      img {
        transform: scale(1.2);
      }
    }
  }

  .modal-footer {
    padding: 0;
    display: flex;
    justify-content: flex-end;
  }
}
