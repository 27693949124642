.navbar-brand {
  font-weight: bold;
}

.navbar {
  box-shadow: 0 3px 4px -1px #707070;
  border-radius: 3px 0;
  width: 100%;
  top: 0;
  background-color: #0096fa;
}

.nav-link {
  color: rgb(255, 255, 255);
  font-weight: bolder;
  z-index: 1;
}

.nav-link.selected,
.nav-link:hover,
.nav-link:visited {
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-thickness: 2px;
  color: white;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar-toggler {
  border-color: white;
}

.Navbar-logo {
  width: 110px;
  filter: brightness(0) invert(1);
  margin-right: 25px;
}

.navbar-nav {
  display: flex;
  justify-content: space-between;
}

.notification {
  text-align: center;
  position: absolute;
  height: 15px;
  width: 18px;
  background-color: #df0050;
  top: 5px;
  border-radius: 40%;
  left: 70px;
  z-index: -1;
  font-size: 10px;
  box-shadow: 0px 0px 3px 1px rgb(255, 255, 255);
  /* box-shadow: 0px 1px 1px 1px rgb(211, 211, 211), -1px -1px 1px 1px rgb(255, 255, 255); */
}

#navbar {
  text-align: left;
}
